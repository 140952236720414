import {Box, Grid, Typography} from '@material-ui/core'
import classNames from 'classnames'
import debounce from 'debounce'
import PropTypes from 'prop-types'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import CustomPlaceholder from '@components/CustomPlaceholder'
import {CustomSelectItem} from '@components/CustomSelect'
import CustomTable, {ColumnData} from '@components/CustomTable'
import CustomTextInput from '@components/CustomTextInput'
import CyclisHeader from '@components/CyclisHeader'
import {TemplateContainerComponent} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {SearchIconSvg, EmptyStateIconSvg} from '@svg/index'
import {
	getBicycles,
	getBicyclesNextPage,
} from '@templates/BicycleOverview/bicycles-slice'
import {NavigationStorageItems} from '@utils/constants/local-storage-constants'
import {LanguageContext} from '@utils/context'
import {formatIsoToPrettyDate} from '@utils/date'
import {EnumDictionary} from '@utils/enum-utils'
import {useThrottledEffect} from '@utils/hooks'
import {SortDirection} from '@utils/hooks/TableDataProvider'
import {toMax15Characters} from '@utils/string-utils'
import {Bicycle, CommonProps} from '@utils/types'
import {BicycleStatus} from '@utils/types/status'

import useStyles from './style.hook'

/**
 * OpenTaskOverview
 */
interface StatusMapper extends CommonProps {
	className: string
	label: string
}

interface OpenTasksOverviewLocation extends Location {
	state: {
		status: BicycleStatus
		searchTerm?: string
		sort?: {
			direction: SortDirection
			property: string
		}
	}
}
interface OpenTaskOverviewProps extends CommonProps {
	location: OpenTasksOverviewLocation
}

const openTaskStatuses = [
	BicycleStatus.CYCLIS_ORDERED,
	BicycleStatus.DEALER_ORDERED,
	BicycleStatus.DELIVERY_DATE_GIVEN,
	BicycleStatus.READY_FOR_PICKUP,
	BicycleStatus.PICKUP_INFO_AVAILABLE,
]

const OpenTaskOverview: React.FC<OpenTaskOverviewProps> = ({location}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()
	const dispatch = useDispatch()
	const {t} = useTranslation(undefined, {useSuspense: false})
	const {activeLanguage} = useContext(LanguageContext)

	const [sort, setSort] = useState(
		(location.state && location.state.sort) || {
			direction: SortDirection.DESC,
			property: 'salesOrderNumber',
		}
	)

	const [searchTerm, setSearchTerm] = useState(
		(location.state && location.state.searchTerm) || ''
	)
	const [bicycleStatus, setBicycleStatus] = useState<string>(
		(location.state && location.state.status) || BicycleStatus.ALL
	)

	const {firstName, lastName} = useSelector((state: RootState) => state.auth)
	const {
		bicycles,
		success: bicyclesSuccess,
		loading: bicyclesLoading,
		paginationHasMoreData,
		paginationCursor,
	} = useSelector((state: RootState) => state.bicycles)

	const rootRef = useRef<any>(null)

	const getBicycleStatusMapping = (): string =>
		bicycleStatus === BicycleStatus.ALL
			? openTaskStatuses.join(',')
			: bicycleStatus

	const statusMapping: EnumDictionary<BicycleStatus, StatusMapper> = {
		[BicycleStatus.ALL]: {
			className: classes.greyPill,
			label: t('BicycleStatusAll'),
		},
		[BicycleStatus.CYCLIS_ORDERED]: {
			className: classes.greyPill,
			label: t('BicycleStatusCyclisOrdered'),
		},
		[BicycleStatus.DEALER_ORDERED]: {
			className: classes.yellowPill,
			label: t('BicycleStatusDealerOrdered'),
		},
		[BicycleStatus.DELIVERY_DATE_GIVEN]: {
			className: classes.redPill,
			label: t('BicycleStatusDeliveryDateGiven'),
		},
		[`${BicycleStatus.READY_FOR_PICKUP},${BicycleStatus.PICKUP_INFO_AVAILABLE}`]:
			{
				className: classes.bluePill,
				label: t('BicycleStatusReadyForPickup'),
			},
	}

	const fillTableSelect = (): CustomSelectItem[] => {
		const selectItems: CustomSelectItem[] = []
		for (let i = 0; i < Object.keys(statusMapping).length; i++) {
			selectItems.push({
				value: Object.keys(statusMapping)[i],
				label:
					statusMapping[Object.keys(statusMapping)[i] as BicycleStatus]!.label,
			})
		}

		return selectItems
	}

	const tableCells: ColumnData[] = [
		{
			id: 0,
			label: t('OpenTaskOverviewTableHeaderSoNum'),
			propertyName: 'salesOrderNumber',
			noTranslate: true,
			toBodyClass: (): string =>
				classNames(classes.tableValue, classes.purpleTableValue),
			toBodyValue: (bike: Bicycle): string =>
				toMax15Characters(bike.salesOrderNumber),
			toTooltipValue: (bike: Bicycle): string => bike.salesOrderNumber,
		},
		{
			id: 1,
			label: t('OpenTaskOverviewTableHeaderName'),
			propertyName: 'cyclistFirstName',
			noTranslate: true,
			toBodyClass: (): string => classNames(classes.tableValue),
			toBodyValue: (bike: Bicycle): string =>
				toMax15Characters(`${bike.cyclistFirstName} ${bike.cyclistLastName}`),
			toTooltipValue: (bike: Bicycle): string =>
				`${bike.cyclistFirstName} ${bike.cyclistLastName}`,
		},
		{
			id: 2,
			label: t('OpenTaskOverviewTableHeaderBicycle'),
			propertyName: 'brand',
			noTranslate: true,
			toBodyClass: (): string => classNames(classes.tableValue),
			toBodyValue: (bike: Bicycle): string =>
				toMax15Characters(`${bike.brand} ${bike.model || 'N/A'}`),
			toTooltipValue: (bike: Bicycle): string =>
				`${bike.brand} ${bike.model || 'N/A'}`,
		},
		{
			id: 3,
			label: t('OpenTaskOverviewTableHeaderCreateDate'),
			dontShortenHeaderLabel: true,
			propertyName: 'createDate',
			toBodyClass: (): string => classNames(classes.tableValue),
			toBodyValue: (bike: Bicycle): string =>
				bike.createDate ? formatIsoToPrettyDate(bike.createDate) : 'N/A',
			toTooltipValue: (bike: Bicycle): string =>
				bike.createDate ? formatIsoToPrettyDate(bike.createDate) : 'N/A',
		},
		{
			id: 4,
			label: t('OpenTaskOverviewTableHeaderExpectedDeliveryDate'),
			dontShortenHeaderLabel: true,
			propertyName: 'expectedDeliveryDate',
			toBodyClass: (): string => classNames(classes.tableValue),
			toBodyValue: (bike: Bicycle): string =>
				bike.expectedDeliveryDate
					? formatIsoToPrettyDate(`${bike.expectedDeliveryDate}`)
					: 'N/A',
			toTooltipValue: (bike: Bicycle): string =>
				bike.expectedDeliveryDate
					? formatIsoToPrettyDate(`${bike.expectedDeliveryDate}`)
					: 'N/A',
		},
		{
			id: 5,
			label: t('OpenTaskOverviewTableHeaderBicycleStatus'),
			propertyName: 'bicycleStatus',
			dropdown: {
				dropdownItems: fillTableSelect(),
				value: bicycleStatus,
				defaultValue: BicycleStatus.ALL,
				multiple: false,
				handleSelectChange: (status: BicycleStatus): void => {
					setBicycleStatus(
						status === BicycleStatus.ALL ? openTaskStatuses.join(',') : status
					)
				},
				handleDropdownRenderValue: ((status: BicycleStatus): string =>
					`Status (${statusMapping[status]!.label})`) as any,
			},
			toBodyClass: (bike: Bicycle): string => {
				const status = Object.keys(statusMapping).find((status) =>
					status.includes(bike.bicycleStatus)
				) as BicycleStatus
				return classNames(
					classes.tableValue,
					classes.pill,
					status ? statusMapping[status]!.className : classes.greyPill
				)
			},
			toBodyValue: (bike: Bicycle): string => {
				const status = Object.keys(statusMapping).find((status) =>
					status.includes(bike.bicycleStatus)
				) as BicycleStatus
				return status ? statusMapping[status]!.label : 'N/A'
			},
			toTooltipValue: (bike: Bicycle): string => {
				const status = Object.keys(statusMapping).find((status) =>
					status.includes(bike.bicycleStatus)
				) as BicycleStatus
				return status ? statusMapping[status]!.label : 'N/A'
			},
		},
	]

	const clickBicycleRow = (bicycle: Bicycle): void => {
		localStorage.setItem(NavigationStorageItems.PREVIOUS_LOCATION, 'open-tasks')

		dispatch(
			navigate(
				`/${activeLanguage}/app/bicycles/detail?id=${bicycle.bicycleId}`,
				{
					state: {
						sort,
						searchTerm,
						bicycleStatus,
					},
				}
			)
		)
	}

	const handleScrollPagination = (): void => {
		if (rootRef.current) {
			const scrollTopMax =
				rootRef.current.scrollHeight - rootRef.current.clientHeight
			const scrollPercent = (rootRef.current.scrollTop / scrollTopMax) * 100

			if (scrollPercent >= 30 && paginationHasMoreData && !bicyclesLoading) {
				dispatch(
					getBicyclesNextPage(
						paginationCursor,
						searchTerm,
						sort.property,
						sort.direction,
						getBicycleStatusMapping()
					)
				)
			}
		}
	}

	const changeSort = (property: string): void => {
		// Flip sorting order if same property
		if (property === sort.property) {
			setSort({
				...sort,
				direction:
					sort.direction === SortDirection.DESC
						? SortDirection.ASC
						: SortDirection.DESC,
			})
		} else {
			// Else set new property as sorting property and default descending order
			setSort({
				direction: SortDirection.DESC,
				property,
			})
		}
	}

	/**
	 * Fetch new bicycles result when status or sort changes
	 */
	useEffect(() => {
		dispatch(
			getBicycles(
				undefined,
				searchTerm,
				sort.property,
				sort.direction,
				getBicycleStatusMapping()
			)
		)
	}, [bicycleStatus, sort])

	useThrottledEffect(
		() => {
			if (!searchTerm || searchTerm.length >= 3) {
				dispatch(
					getBicycles(
						undefined,
						searchTerm,
						sort.property,
						sort.direction,
						getBicycleStatusMapping()
					)
				)
			}
		},
		1000,
		[searchTerm]
	)

	const HeaderBox: React.FC = () => (
		<Box id={`open-tasks-stats-box`} className={classes.statsGrid}>
			<Grid id={`open-tasks-stats-grid`} spacing={8} container>
				<Grid item xs={1} />
				<Grid item xs={10}>
					<Box id={`open-tasks-stats-header-box`} className={classes.headerBox}>
						<Box id={`open-tasks-stats-title-box`} className={classes.titleBox}>
							<Typography
								id={`open-tasks-header-title`}
								className={classes.headerTitle}
								variant={'h1'}
							>
								{t('OpenTaskOverviewHeaderTitle')}
							</Typography>
							<Typography
								id={`open-tasks-stats-header-subtitle`}
								className={classes.headerSubTitle}
								variant={'body1'}
							>
								{t('OpenTaskOverviewHeaderSubTitle')}
							</Typography>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={1} />
			</Grid>
		</Box>
	)

	return (
		<div>
			<TemplateContainerComponent
				id={'open-tasks-container'}
				className={classes.root}
				propsToDelegate={{
					onScroll: debounce(handleScrollPagination, 600),
					ref: rootRef,
				}}
			>
				<CyclisHeader customerName={`${firstName} ${lastName}`} />
				<HeaderBox />
				<CustomTextInput
					id={`open-tasks-overview-search-input`}
					className={classes.searchInput}
					iconLeft={<SearchIconSvg />}
					helperText={
						(searchTerm &&
							searchTerm.length < 3 &&
							t('SearchAtleast3Characters')) as string
					}
					helperTextId={'open-tasks-overview-search-helper-text'}
					propsToDelegate={{
						placeholder: t('OpenTaskOverviewSearchInputPlaceholder'),
						value: searchTerm,
						onChange: (e): void => setSearchTerm(e.target.value),
					}}
				/>
				<Grid container spacing={8}>
					<Grid item xs={1} />
					<Grid item xs={10}>
						<CustomTable
							id={'bicycles'}
							columnsData={tableCells}
							tableEntries={bicycles}
							sort={sort}
							handleCellClick={clickBicycleRow}
							changeSort={changeSort}
							placeholder={
								bicyclesSuccess &&
								!bicyclesLoading &&
								bicycles.length === 0 && (
									<CustomPlaceholder
										id={'open-tasks-placeholder'}
										icon={<EmptyStateIconSvg className={classes.icon} />}
										headerText={t('OpenTaskOverviewCustomPlaceholderHeader')}
										subTitle={t('OpenTaskOverviewCustomPlaceholderSubTitle')}
									/>
								)
							}
						/>
					</Grid>
					<Grid item xs={1} />
				</Grid>
				<Box className={classes.bottomPadding} />
			</TemplateContainerComponent>
		</div>
	)
}

OpenTaskOverview.propTypes = {
	location: PropTypes.any,
}

export default OpenTaskOverview
